import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = ({ description, lang, meta, keywords, title, openGraphImage, twitterOpenGraphImage }) => {
   const { site } = useStaticQuery(graphql`
      query DefaultSEOQuery {
         site {
            siteMetadata {
               author
               siteUrl
            }
         }
      }
   `);

   const metaDescription = description || site.siteMetadata.description;
   const socialDefault = `${site.siteMetadata.siteUrl}${openGraphImage}`;
   const socialTwitter = `${site.siteMetadata.siteUrl}${twitterOpenGraphImage}`;

   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         meta={[
            {
               name: `description`,
               content: metaDescription,
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:image`,
               content: socialDefault,
            },
            {
               property: `og:description`,
               content: metaDescription,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata.author,
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: metaDescription,
            },
            {
               name: `twitter:image`,
               content: socialTwitter,
            },
         ]
            .concat(
               keywords.length > 0
                  ? {
                       name: `keywords`,
                       content: keywords.join(`, `),
                    }
                  : []
            )
            .concat(meta)}
         title={title}
         titleTemplate={`%s`}
      />
   );
};

SEO.defaultProps = {
   lang: `en`,
   keywords: [],
   meta: [],
};

SEO.propTypes = {
   description: PropTypes.string,
   keywords: PropTypes.arrayOf(PropTypes.string),
   lang: PropTypes.string,
   meta: PropTypes.array,
   title: PropTypes.string,
   twitterOpenGraphImage: PropTypes.string,
   openGraphImage: PropTypes.string,
};

export default SEO;
