import React from "react";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

// import BlogRecentPosts from "../Blog/BlogRecentPosts";
// import CTABackgroundColor from "../CTA/CTABackgroundColor";

const StyledBlogPost = styled.div`
   p,
   li,
   span,
   b {
      ${tw`text-base`}
   }
   p,
   ol,
   ul {
      ${tw`mb-12`}
   }
   ul,
   ol {
      ${tw`ml-8`}
      li {
         ${tw`mb-6`}
      }
      ul,
      ol {
         ${tw`mt-6`}
      }
   }
   ul {
      ${tw`list-disc`}
   }
   ol {
      ${tw`list-decimal`}
   }
   @media (min-width: 768px) {
      p,
      li,
      span,
      b {
         ${tw`text-xl`}
      }
      p,
      ol,
      ul {
         ${tw`mb-12`}
      }
   }
   a {
      ${tw`text-primary hover:text-primary_light`}
   }
`;

const Blog = ({ featuredImage, ...props }) => {
   return (
      <div>
         <section className={`wrapper pt-8 md:pt-12`}>
            <div className="container">
               <div className="max-w-[650px] mx-auto">
                  <header className="mb-12 md:mb-14">
                     <h1>{props.title}</h1>
                     <span> {props.date}</span> <span className="mx-1">/</span> <span>{props.author} </span>
                  </header>

                  <Img fadeIn={false} loading="eager" fluid={featuredImage} alt={props.title} />

                  <div className="mt-12 md:mt-16">
                     <StyledBlogPost>{props.content}</StyledBlogPost>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
};

export default Blog;
