import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="VARTM: When You Need Durable, Large-Scale Composite Parts"
            description="Vacuum assisted resin transfer molding (VARTM) "
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="VARTM: When You Need Durable, Large-Scale Composite Parts"
            date="February 23, 2022"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     VARTM stands for vacuum assisted resin transfer molding. It’s a manufacturing technique used to create high-quality composite
                     parts that are cost-effective and durable without being too heavy.
                  </p>

                  <h2>How the VARTM Method Works</h2>
                  <p>
                     To manufacture composite parts using the VARTM method, manufacturers use high-performance, e-glass fibers and low-viscosity
                     resins. The technique also involves the use of a closed mold system that operates on low pressure. A vacuum is added at the exit
                     vent of the molding tool. Sitting between the two molds is an e-glass fiber that helps with reinforcement. The mold is sealed by
                     a vacuum bag and the fiber is compressed against the surface of the mold using external atmospheric pressure.
                  </p>

                  <p>
                     A vacuum bag replaces the top part of the mold tool and helps infuse the resin into the fiber layup. Low vacuum pressure removes
                     air bubbles from the preform and strengthens the resin.
                  </p>

                  <h2>Industries That Use the VARTM Process</h2>
                  <p>There are many different industries that rely on the VARTM process to create complex pieces. They include:</p>
                  <ul>
                     <li>Medical</li>
                     <li>Wind energy</li>
                     <li>Aerospace</li>
                     <li>Transportation</li>
                     <li>Recreational</li>
                     <li>Infrastructure</li>
                     <li>Marine market</li>
                  </ul>

                  <p>
                     At Painted Rhino, we can create large composite parts using the VARTM method for practically any industry that requires it.
                     Examples of parts we can make using this method include bridge beams, aircraft fuselage sections and landing gear doors, wind
                     turbine blades, and boat hulls.
                  </p>

                  <h2>What Are the Processing Steps for VARTM?</h2>
                  <p>Usually, the VARTM method involves three distinct steps. They are:</p>
                  <ul>
                     <li>
                        Step 1: Prepare the material. During this step, we place the fibers, preforms and reinforcing materials into a mold. We also
                        degass blends components and mix resins. During this process, we use digital leak rate equipment to make sure the finished
                        product is of the highest quality.
                     </li>
                     <li>Step 2: Infuse the resin into the reinforcement. We use vacuum pressure to complete this complex process.</li>
                     <li>
                        Step 3: Complete the post-infusion process. During this process, excess resin moves toward a vent as it bleeds into the vacuum
                        trap. This process leads to improved fiber volume fraction and decreased thickness.
                     </li>
                  </ul>

                  <p>
                     There are many reasons to choose the VARTM method for your manufacturing needs. It is cost-effective and reliably creates strong
                     composite parts without adding excessive weight. The process is done without special equipment (such as an autoclave) and it’s
                     associated with lower tooling costs. Additionally, the VARTM method is scalable and does not require you to make numerous small
                     orders.
                  </p>

                  <h2>Schedule Your Custom Manufacturing Solution Consultation Today</h2>
                  <p>
                     If you want cost-effective high-quality components without the typical high cost associated with other manufacturing methods,
                     consider ordering custom parts manufactured with the VARTM method. You can order pieces with both mechanical and dielectric
                     strength and minimize your manufacturing costs.
                  </p>

                  <p>
                     Here at Painted Rhino, we have decades of experience offering innovative manufacturing solutions to our clients. As a result, we
                     can meet a wide variety of manufacturing needs with precision and speed. <a href="tel:951-656-5524">Contact us today</a> to
                     schedule a consultation and discover how we can use the VARTM method to create the parts you need.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/How does the VARTM method work.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
